/**
 * @name: 销售管理-档口交易流水接口文件
 * @author:lili
 * @date: 2023-08-04 17:46
 * @description： 销售管理-档口交易流水接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISaleFlow, ISaleFlowParams} from "@/apis/sale/flow/types";


/**
 * 查询档口交易流水列表
 * @param params 查询参数
 * @returns
 */
export const saleOrderQueryApi = (params: ISaleFlowParams) => postJ(`/admin/sale/order/query?page=${params.page}&limit=${params.limit}`, params)

/**
 * 查询档口交易流水导出
 * @param params 查询参数
 * @returns
 */
export const saleOrderExportApi = (params: ISaleFlowParams) => postJ("/admin/sale/order/export", params,"blob")
/**
 * 获取档口交易流水详情
 * @param id
 * @returns
 */
export const saleOrderDetailApi = (id: string) => get<ISaleFlow>("/admin/sale/order/detail/" + id)

