
/**
 * @name: 销售管理-档口交易流水
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 销售管理-档口交易流水
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {saleOrderDetailApi, saleOrderExportApi, saleOrderQueryApi} from "@/apis/sale/flow";
import {deepCopy, exportFile, getPreAndNextTime} from "@/utils/common";
import {ISaleFlow, ISaleFlowParams} from "@/apis/sale/flow/types";
import Big from "big.js";

@Component({})
export default class saleFlow extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISaleFlowParams = {
    page: 1,
    limit: 10,
    payWay: [],
    status: [],
    gateIds: [],
    payTime: []
  }
  pageData: any = {
    orderPrice: '',
    payPrice: ''
  }
  // 表单参数
  modelForm: Partial<ISaleFlow> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    menu: true,
    labelWidth: '160px',
    column: [
      {
        label: '交易流水ID',
        prop: 'id',
        align: 'left',
        search: true,
        width: 180
      },
      {
        label: '销售订单编号',
        prop: 'saleOrderSn',
        align: 'left',
        width: 180
      },

      {
        label: "档口名称",
        prop: "gateIds",
        align: "center",
        search: true,
        searchMultiple: true,
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        type: 'select',
        hide: true,
        viewHide: true
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center",
        span: 12
      },
      {
        label: "订单金额",
        prop: "orderPrice",
        align: "center",
        span: 12,
        viewHide: true
      },
      {
        label: "支付金额",
        prop: "payPrice",
        align: "center",
        span: 12
      },
      {
        label: "抹零金额",
        prop: "wipeZeroPrice",
        align: "center",
        span: 12,
        viewHide: true
      },
      {
        label: "交易方式",
        prop: "payWay",
        align: 'center',
        search: true,
        searchMultiple: true,
        type: "select",  //1=现金 2=微信 3=支付宝 4=混合支付 5=财务结算
        dicData: [
          {label: '现金', value: 1},
          {label: '微信', value: 2},
          {label: '支付宝', value: 3},
          {label: '混合支付', value: 4},
          {label: '财务结算', value: 5},
          {
            label: "抖音",
            value: 6
          },
        ],
        span: 12,
      },
      {
        label: "交易状态",
        prop: "status",
        align: 'center',
        search: true,
        searchMultiple: true,
        type: "select", //1=未支付 2=已支付 3=挂账 4=废单审核中 5=已废弃 6=退货审核中 7=退货 8=挂单 9=已取消
        dicData: [
          {label: '未支付', value: 1},
          {label: '已支付', value: 2},
          {label: '挂账', value: 3},
          {label: '废单审核中', value: 4},
          {label: '已废弃', value: 5},
          {label: '退货审核中', value: 6},
          {label: '退货', value: 7},
          {label: '挂单', value: 8},
          {label: '已取消', value: 9}],
        span: 12,
      },
      {
        label: "经办人",
        prop: "salesPersonName",
        align: "center",
        search: true,
        viewHide: true
      },

      {
        label: "交易时间",
        prop: "payTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
        span: 12,
        viewHide: true
      },
      {
        label: "",
        prop: "time",
        align: "center",
        width: 180,
        //@ts-ignore
        default: '--',
        search: true,
        searchSlot: true,
        hide: true,
        viewHide: true
      },
      {
        label: "备注",
        prop: "remarks",
        align: 'center',
        type: 'textarea',
        overHidden: true,
        width: 180,
        viewHide: true
      },
      {
        label: "支付明细",
        prop: "orderPayStr",
        align: "center",
        hide: true,
      },
      {
        label: "挂账客户",
        prop: "hangingCustomer",
        align: "center",
        hide: true,
      },
    ]
  }

  //计算 明细 -商品金额合计
  get amountTotal() {
    let sum: number = 0;
    if (this.modelForm.saleOrderDtlVos && this.modelForm.saleOrderDtlVos.length > 0) {
      for (let i = 0; i < this.modelForm.saleOrderDtlVos.length; i++) {
       // let s= new Big(this.modelForm.saleOrderDtlVos[i]["price"] || 0).times(this.modelForm.saleOrderDtlVos[i]["qty"] || 0).plus(sum).toFixed(2,0);
        sum =new Big(sum).plus(this.modelForm.saleOrderDtlVos[i]["productPrice"] || 0).toNumber()
      }
    }
    return sum.toFixed(2)
  }

  /**
   * 详情
   * @param row
   */
  openDetail(row: ISaleFlow, index: number) {
    saleOrderDetailApi(row.id as string).then(e => {
      if (e) {
        // @ts-ignore
        e.orderPayStr = '--';
        if (e.orderPayVos) {
          // @ts-ignore
          e.orderPayStr = '';
          e.orderPayVos.forEach(item => {
            // 类型:1=微信 2=支付宝 3=现金
            // @ts-ignore
            e.orderPayStr += `${item.types == 1 ? '微信' : item.types == 2 ? '支付宝' :item.types == 3 ? '现金':'抖音'} ${item.payPrice}    `
          })
        }

        // @ts-ignore
        e.hangingCustomer ='--';
        if(e.hangingCustomerName&&e.hangingCustomerPhone){
          // @ts-ignore
          e.hangingCustomer = `${e.hangingCustomerName } ${e.hangingCustomerPhone}`
        }
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: ISaleFlowParams = deepCopy(this.queryParam);
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      form.payStartTime = this.queryParam.payTime[0]
      form.payEndTime = this.queryParam.payTime[1]
    } else {
      form.payStartTime = ""
      form.payEndTime = ""
    }
    if (this.queryParam.time && this.queryParam.time.length) {
      form.startTime = this.queryParam.time[0]
      form.endTime = this.queryParam.time[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.time
    saleOrderExportApi(form).then(e => {
      exportFile(e, '档口交易流水.xlsx')
    })
  }
  /**
   * 获取
   */
  getList() {
    const form: ISaleFlowParams = deepCopy(this.queryParam);
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      form.payStartTime = this.queryParam.payTime[0]
      form.payEndTime = this.queryParam.payTime[1]
    } else {
      form.payStartTime = ""
      form.payEndTime = ""
    }
    if (this.queryParam.time && this.queryParam.time.length) {
      form.startTime = this.queryParam.time[0]
      form.endTime = this.queryParam.time[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.time
    saleOrderQueryApi(form).then(e => {
      this.pageData.orderPrice = e.orderPrice || 0;
      this.pageData.payPrice = e.payPrice || 0;
      let {commonPage} = e;
      //@ts-ignore
      this.tableData = commonPage.list;
      this.tableTotal = commonPage.total
    })
  }

  reset () {
    const dateRange = getPreAndNextTime("06:00:00", "05:59:59")
    this.queryParam.payTime = dateRange;
    this.queryParam.time =['00:00:00','23:59:59'];
    this.getList()
  }
  created() {
    this.reset()
  }
}
